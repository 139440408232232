import React from 'react';

import AsyncRoleSelect from '../../components/ParticipantDetails/Tree/filters/AsyncRoleSelect';
import { RangePicker } from '@crpt/react-datepicker';

import { HomePage } from '../../components/HomePage/HomePage';
import { Violation } from '../../components/Violation/Violation';
import { Statistic } from '../../components/Statistic/Statistic';
import { Excises } from '../../components/Excises/Excises';
import { Appeals } from '../../components/Appeals/Appeals';
import Dashboard from '../../components/Dashboard';
import ProductDetailsBound from '../../containers/Bounds/ProductDetailsBound';
import Requests from '../../motp_components/_Requests/Requests';
import EdoDocument from '../../motp_components/_Requests/EdoDocument';
import ParticipantList from '../../components/ParticipantList/ParticipantList';
import LoginFormKep from '../../components/LoginFormKep/LoginFormKep';
import LoginForm from '../../components/LoginForm/LoginForm';
//import CisDetailBound from '../../containers/Bounds/CisDetailBound';
import CisDetails from '../../components/CisDetails/CisDetails';
import DocumentDetails from '../../components/DocumentDetails/DocumentDetails';
import Receipt from '../../components/DocumentDetails/Receipt/Receipt';
import AltScenariosBound from '../../containers/Bounds/AltScenariosBound';
import Auth from '../../common_components/Auth/Auth';
import Registration from '../../containers/Bounds/RegistrationBound';
import DefaultWrap from '../../common_components/DefaultWrap/DefaultWrap';
import ParticipantDetailsBound from '../../containers/Bounds/ParticipantDetailsBound';
import ProductListBound from '../../containers/Bounds/ProductListBound';
import AltScenarioCisListBound from '../../containers/Bounds/AltScenarioCisListBound';
import CISList from '../../components/CisList/CISList.js';
import AfterLogin from '../../components/AfterLogin';

import GrayArea from '../../components/GrayArea/GrayArea';
// import Calendar from '../../components/GrayArea/Calendar'; // DELETE
// import RetailList from '../../components/GrayArea/RetailList';
import UnloadServiceList from '../../components/UnloadService/UnloadServiceList/UnloadServiceList';
import UnloadServiceDetails from '../../components/UnloadService/UnloadServiceDetails/UnloadServiceDetails';

import { singlePercentChartUsualTheme } from '../../theme/ru-tabaco/singlePercentChart';
import { multiPercentChartUsualTheme } from '../../theme/ru-tabaco/multiPercentChart';
import { smallLineChartUsualTheme } from '../../theme/ru-tabaco/smallLineChart';

import { Informer } from '../../components/Informer';
import Reports from '../../components/Reports/Reports';

import { Icon } from '@material-ui/core';
import Icons from '../../common_components/Icons/Icons';

import {
  sidebarDefaultTheme,
  sidebarLoginTheme,
  sidebarRegistrationTheme,
} from '../../theme/ru-tabaco/sidebar';

import {
  pageLayoutDefaultTheme,
  pageLayoutLoginTheme,
} from '../../theme/ru-tabaco/pageLayout';

import {
  selectRegistrationTheme,
  selectLoginTheme,
} from '../../theme/ru-tabaco/select';

import { headerDefaultTheme } from '../../theme/ru-tabaco/header';
import { contentDefaultTheme } from '../../theme/ru-tabaco/content';
import { tableFilterDefaultTheme } from '../../theme/ru-tabaco/tableFilter';
import { inputDefaultTheme } from '../../theme/ru-tabaco/input';
import { tableDefaultTheme } from '../../theme/ru-tabaco/table';
import { menuDefaultTheme } from '../../theme/ru-tabaco/menu';
import {
  buttonDefaultTheme,
  buttonWhiteTheme,
  buttonTabItemTheme,
  buttonSelectedTabItemTheme,
} from '../../theme/ru-tabaco/button';
import { actionBarDefaultTheme } from '../../theme/ru-tabaco/actionBar';
import { reportsListDefaultTheme } from '../../theme/ru-tabaco/reportsList';
import * as lists from './lists';
import * as translate from './translate';
import { dropdownDefaultTheme } from '../../theme/ru-tabaco/dropdown';
import { defaultDatepickerTheme } from '../../theme/ru-tabaco/datepicker';

// ========= Theme for KZ ========= //

import { singlePercentChartUsualThemeKZ } from '../../theme/kz-tobaco/singlePercentChart';
import { multiPercentChartUsualThemeKZ } from '../../theme/kz-tobaco/multiPercentChart';
import { smallLineChartUsualThemeKZ } from '../../theme/kz-tobaco/smallLineChart';

import {
  sidebarDefaultThemeKZ,
  sidebarLoginThemeKZ,
  sidebarRegistrationThemeKZ,
} from '../../theme/kz-tobaco/sidebar';

import {
  pageLayoutDefaultThemeKZ,
  pageLayoutLoginThemeKZ,
} from '../../theme/kz-tobaco/pageLayout';

import {
  selectRegistrationThemeKZ,
  selectLoginThemeKZ,
} from '../../theme/kz-tobaco/select';

import { headerDefaultThemeKZ } from '../../theme/kz-tobaco/header';
import { contentDefaultThemeKZ } from '../../theme/kz-tobaco/content';
import { tableFilterDefaultThemeKZ } from '../../theme/kz-tobaco/tableFilter';
import { inputDefaultThemeKZ } from '../../theme/kz-tobaco/input';
import { tableDefaultThemeKZ } from '../../theme/kz-tobaco/table';
import { menuDefaultThemeKZ } from '../../theme/kz-tobaco/menu';
import {
  buttonDefaultThemeKZ,
  buttonWhiteThemeKZ,
  buttonTabItemThemeKZ,
  buttonSelectedTabItemThemeKZ,
} from '../../theme/kz-tobaco/button';
import { actionBarDefaultThemeKZ } from '../../theme/kz-tobaco/actionBar';
import { reportsListDefaultThemeKZ } from '../../theme/kz-tobaco/reportsList';
import { dropdownDefaultThemeKZ } from '../../theme/kz-tobaco/dropdown';
import { defaultDatepickerThemeKZ } from '../../theme/kz-tobaco/datepicker';

// ========= Theme for UZ ========= //

import { singlePercentChartUsualThemeUZ } from '../../theme/uz-tobaco/singlePercentChart';

import { selectLoginThemeUZ } from '../../theme/uz-tobaco/select';

import { menuDefaultThemeUZ } from '../../theme/uz-tobaco/menu';
import {
  buttonDefaultThemeUZ,
  buttonWhiteThemeUZ,
  buttonTabItemThemeUZ,
  buttonSelectedTabItemThemeUZ,
} from '../../theme/uz-tobaco/button';
import { reportsListDefaultThemeUZ } from '../../theme/uz-tobaco/reportsList';
import { dropdownDefaultThemeUZ } from '../../theme/uz-tobaco/dropdown';

import {
  ADMINS,
  ADMIN_USER_AUTHORITIES,
  CAN_SEE_REPORTS,
  CAN_SEE_PRODUCTS,
  CAN_SEE_HOMEPAGE,
  CAN_SEE_PARTICIPANTS,
  CAN_SEE_GRAY_AREA,
  CAN_SEE_UNLOAD_SERVICE,
  CAN_SEE_DOCUMENTS,
  CAN_SEE_MOBILE,
  canSeeCodes,
  canSeeProducts,
  canSeeMobile,
  canSeeReports,
  canSeeParticipants,
  canSeeAppeals,
  canSeeByReportOrOgv,
  canSeeDocuments,
  canSeeNewReports,
} from './permissions';

import {
  environment,
  environmentLinks,
  environmentEnum,
  countryCodeFlags,
} from '../../constants';
import ExportEaes from '../../components/DocumentDetails/ExportEAES/ExportEaes';
import UnauthorizedParticipants from '../../components/ParticipantList/UnauthorizedParticipants/UnauthorizedParticipants';
import { stubFalse } from 'lodash/fp';
import ArticleListPage from '../../components/ArticleListPage';
import ArticleCreation from '../../components/ArticleCreation';
import ArticlePreviewView from '../../components/ArticlePreview';

const iconStyle = {
  width: '18px',
  height: '18px',
};

const createSupersetComponent = path => props => {
  const url = window.env.REACT_APP_SUPERSSET_URL + path;
  window.open(url, '_blank', 'noopener,noreferrer');
  props.history.goBack();
  return null;
};

export const motp = {
  ADMIN_USER_AUTHORITIES,
  ADMINS,
  translate,
  LayoutComponent: DefaultWrap,
  LoginForm: {
    component: LoginForm,
  },

  ParticipantDetails: {
    Tree: {
      filters: [
        {
          showDownloadListButton: true,
          popupMenu: false,
          retailOrganisation: true,
        },
        [
          {
            component: AsyncRoleSelect,
          },
        ],
        [
          {
            component: RangePicker,
            props: {
              name: 'date',
              inline: false,
              placeholder: 'Дата',
              format: 'DD.MM.YYYY',
              savePlaceholder: true,
              theme: { DatePicker: defaultDatepickerTheme },
            },
          },
        ],
      ],
    },
  },
  docs: {
    reg: '/docs/МОТП_инструкция_КРИПТО_про.pdf',
  },
  routes: [
    {
      route: '/cis/list',
      exact: true,
      private: true,
      inLayout: true,
      component: CISList,
    },
    {
      route: '/login',
      exact: true,
      private: false,
      inLayout: false,
      component: props => {
        if (environment === environmentEnum.DEV) {
          return React.createElement(LoginForm, props);
        }

        window.location.href = window.env.REACT_APP_ELK_URL + '/login';
        return null;
      },
    },
    {
      route: '/login-kep',
      exact: true,
      private: false,
      inLayout: false,
      component: props => {
        if (environment === environmentEnum.DEV) {
          return React.createElement(LoginFormKep, props);
        }

        window.location.href = window.env.REACT_APP_ELK_URL + '/login-kep';
        return null;
      },
    },
    {
      route: '/',
      private: false,
      exact: true,
      component: Auth,
    },
    {
      route: '/violation',
      exact: false,
      private: true,
      inLayout: true,
      component: Violation,
    },
    {
      route: '/violation-new',
      exact: true,
      private: true,
      inLayout: true,
      component: createSupersetComponent(
        '/superset/dashboard/violation-new/?standalone=2',
      ),
    },
    {
      route: '/statistic',
      exact: false,
      private: true,
      inLayout: true,
      component: Statistic,
    },
    {
      route: '/statistic-new',
      exact: true,
      private: true,
      inLayout: true,
      component: createSupersetComponent(
        '/superset/dashboard/statistic-new/?standalone=2',
      ),
    },
    {
      route: '/excise',
      exact: false,
      private: true,
      inLayout: true,
      component: Excises,
    },
    {
      route: '/excise-new',
      exact: true,
      private: true,
      inLayout: true,
      component: createSupersetComponent(
        '/superset/dashboard/excise-new/?standalone=2',
      ),
    },
    {
      route: '/appeals',
      exact: true,
      private: true,
      inLayout: true,
      component: Appeals,
    },
    {
      route: '/home',
      exact: true,
      private: true,
      inLayout: true,
      component: HomePage,
    },
    {
      route: '/mobile-content/articles',
      exact: true,
      private: true,
      inLayout: true,
      component: ArticleListPage,
    },
    {
      route: '/mobile-content/articles/create',
      exact: true,
      private: true,
      inLayout: true,
      component: ArticleCreation,
    },
    {
      route: '/mobile-content/articles/update/:lang/:id',
      exact: true,
      private: true,
      inLayout: true,
      component: ArticleCreation,
    },
    {
      route: '/mobile-content/articles/copy/:lang/:id',
      exact: true,
      private: true,
      inLayout: true,
      component: ArticleCreation,
    },
    {
      route: '/mobile-content/articles/:id',
      exact: true,
      private: true,
      inLayout: true,
      component: ArticleCreation,
    },
    {
      route: '/mobile-content/articles/preview/:id',
      exact: true,
      private: true,
      inLayout: true,
      component: ArticlePreviewView,
    },
    {
      route: '/cis/scenario/:type',
      private: true,
      inLayout: true,
      component: AltScenarioCisListBound,
    },
    {
      route: '/cis/list/:id',
      private: true,
      inLayout: true,
      component: CisDetails,
    },
    {
      route:
        '/:folder(documents)/:direction(incoming|outgoing)/:typeName/:id/:clarification?',
      private: true,
      inLayout: true,
      component: EdoDocument,
    },
    {
      route: '/:folder(documents)/:type(incoming|outgoing)/list',
      exact: true,
      private: true,
      inLayout: true,
      component: Requests,
    },
    {
      route: '/documents/list/:id/:key?',
      private: true,
      inLayout: true,
      component: DocumentDetails,
    },
    {
      route: '/documents/admits/:id/:key?',
      private: true,
      inLayout: true,
      component: DocumentDetails,
    },
    {
      route: '/documents/receipt/:id',
      private: true,
      inLayout: true,
      component: Receipt,
    },
    {
      route: '/documents/export/:id',
      private: true,
      inLayout: true,
      component: ExportEaes,
    },
    {
      route: '/documents',
      private: true,
      inLayout: true,
      component: Requests,
    },
    // {
    //   route: '/gray-area/retail',
    //   private: true,
    //   inLayout: true,
    //   component: RetailList,
    // },
    // { // DELETE
    //   route: '/calendar',
    //   private: true,
    //   inLayout: true,
    //   component: Calendar,
    // },
    // { // DELETE
    //   route: '/retail',
    //   private: true,
    //   inLayout: true,
    //   component: RetailList,
    // },
    {
      route: '/requests',
      private: true,
      inLayout: true,
      component: Requests,
    },
    {
      route: '/after-login',
      exact: true,
      component: AfterLogin,
    },
    {
      route: '/informer',
      private: false,
      inLayout: false,
      skipHeader: true,
      component: () => <Informer outland />,
    },
    {
      route: '/reports',
      private: true,
      exact: false,
      inLayout: true,
      component: Reports,
    },
    {
      route: '/reports-new',
      private: true,
      exact: true,
      inLayout: true,
      component: createSupersetComponent('/login/egaSSO_KEP'),
    },
    {
      route: '/register',
      private: false,
      inLayout: false,
      component: props => {
        if (environment === environmentEnum.DEV) {
          return React.createElement(Registration, props);
        }

        window.location.href = window.env.REACT_APP_ELK_URL + '/register';
        return null;
      },
    },
  ],
  menuItems: [
    {
      route: '/home',
      title: 'Главная',
      icon: 'Home',
      titleKey: 'HOME.HEADING',
    },
    // {
    //   route: '/charts',
    //   title: 'Сводная',
    //   icon: 'Charts',
    //   titleKey: 'CHARTS.HEADING',
    //   orgRoles: CAN_SEE_HOMEPAGE,
    // },
    {
      route: '/cis/list',
      title: 'Коды',
      icon: 'Scan',
      titleKey: 'CIS_LIST.HEADING',
      checkPermissionFunc: canSeeCodes,
    },
    {
      route: '/documents/list',
      title: 'Документы',
      testRoute: '/documents',
      icon: 'Documents',
      titleKey: 'DOCUMENT_LIST.HEADING',
      // orgRoles: CAN_SEE_DOCUMENTS,
      checkPermissionFunc: canSeeDocuments,
    },
    {
      route: '/products/list',
      title: 'Товары',
      icon: 'Products',
      titleKey: 'PRODUCT_LIST.HEADING',
      // orgRoles: CAN_SEE_PRODUCTS,
      checkPermissionFunc: canSeeProducts,
    },
    {
      route: '/violation',
      title: 'Аналитические отклонения',
      icon: <Icon component={Icons.NoteRemove} style={iconStyle} />,
      titleKey: 'Аналитические отклонения',
      // orgRoles: CAN_SEE_PARTICIPANTS,
      checkPermissionFunc: canSeeByReportOrOgv,
    },
    {
      route: '/violation-new',
      title: 'Аналитические отклонения (новый)',
      icon: <Icon component={Icons.NoteRemove} style={iconStyle} />,
      titleKey: 'Аналитические отклонения (новый)',
      // orgRoles: CAN_SEE_PARTICIPANTS,
      checkPermissionFunc: canSeeByReportOrOgv,
    },
    {
      route: '/statistic',
      title: 'Статистика',
      icon: <Icon component={Icons.PresentationNotes} style={iconStyle} />,
      titleKey: 'Статистика',
      // orgRoles: CAN_SEE_PARTICIPANTS,
      checkPermissionFunc: canSeeByReportOrOgv,
    },
    {
      route: '/statistic-new',
      title: 'Статистика (новый)',
      icon: <Icon component={Icons.PresentationNotes} style={iconStyle} />,
      titleKey: 'Статистика (новый)',
      // orgRoles: CAN_SEE_PARTICIPANTS,
      checkPermissionFunc: canSeeByReportOrOgv,
    },
    {
      route: '/excise',
      title: 'Цифровой акциз',
      icon: <Icon component={Icons.Calculator} style={iconStyle} />,
      titleKey: 'Цифровой акциз',
      // orgRoles: CAN_SEE_PARTICIPANTS,
      checkPermissionFunc: canSeeByReportOrOgv,
    },
    {
      route: '/excise-new',
      title: 'Цифровой акциз (новый)',
      icon: <Icon component={Icons.Calculator} style={iconStyle} />,
      titleKey: 'Цифровой акциз (новый)',
      // orgRoles: CAN_SEE_PARTICIPANTS,
      checkPermissionFunc: canSeeByReportOrOgv,
    },
    {
      route: '/appeals',
      title: 'Обращения',
      icon: <Icon component={Icons.Message} style={iconStyle} />,
      titleKey: 'Обращения',
      // orgRoles: CAN_SEE_PARTICIPANTS,
      checkPermissionFunc: canSeeAppeals,
    },
    {
      route: '/participants/list',
      // orgRoles: CAN_SEE_PARTICIPANTS,
      title: 'Участники',
      icon: 'Participants',
      titleKey: 'PARTICIPANT_LIST.HEADING',
      checkPermissionFunc: canSeeParticipants,
    },
    {
      // orgRoles: CAN_SEE_REPORTS,
      route: '/reports',
      title: 'Отчёты',
      icon: 'Reports',
      titleKey: 'Отчёты',
      checkPermissionFunc: canSeeReports,
    },
    {
      route: '/reports-new',
      title: 'Отчеты (новые)',
      icon: 'Reports',
      titleKey: 'Отчеты (новые)',
      checkPermissionFunc: canSeeNewReports,
    },
    // {
    //   route: '/unload-service/list',
    //   title: 'Выгрузки',
    //   icon: 'Export',
    //   titleKey: 'Выгрузки',
    //   orgRoles: CAN_SEE_UNLOAD_SERVICE,
    // },
    // {
    //   route: '/gray-area',
    //   title: 'Серая зона',
    //   icon: 'Rejection',
    //   titleKey: 'Серая зона',
    //   orgRoles: CAN_SEE_GRAY_AREA,
    // },
    // {
    //   // Item is used for Header->NavigationInfo. It is newer be showed.
    //   route: '/profile',
    //   title: 'Профиль',
    //   icon: '',
    //   titleKey: 'Профиль',
    //   hide: true,
    //   for: 'NAVIGATION_INFO_FROM_HEADER',
    // },
    {
      // Item is used for Header->NavigationInfo. It is newer be showed.
      route: '/documents/draft',
      title: 'Черновики',
      icon: '',
      titleKey: 'Черновики',
      hide: true,
      for: 'NAVIGATION_INFO_FROM_HEADER',
    },
    {
      // Item is used for Header->NavigationInfo. It is newer be showed.
      route: '/profile/users/list',
      title: 'Пользователи',
      icon: '',
      titleKey: 'Пользователи',
      hide: true,
      for: 'NAVIGATION_INFO_FROM_HEADER',
    },
    {
      // Item is used for Header->NavigationInfo. It is newer be showed.
      route: '/profile/excise',
      title: 'Акцизный налог',
      icon: '',
      titleKey: 'Акцизный налог',
      hide: true,
      for: 'NAVIGATION_INFO_FROM_HEADER',
    },
    {
      // orgRoles: CAN_SEE_MOBILE,
      // authorities: CAN_SEE_MOBILE,
      route: '/mobile-content/articles',
      title: 'Мобильный контент',
      icon: <Icon component={Icons.Mobile} style={{ width: 19 }} />,
      titleKey: 'MOBILE_CONTETNT.HEADING',
      checkPermissionFunc: canSeeMobile,
    },
  ],
  lists,
  theme: {
    Table: tableDefaultTheme,
    Menu: menuDefaultTheme,
    Sidebar: sidebarDefaultTheme,
    PageLayout: pageLayoutDefaultTheme,
    Header: headerDefaultTheme,
    Content: contentDefaultTheme,
    TableFilter: tableFilterDefaultTheme,
    Input: inputDefaultTheme,
    Select: selectLoginTheme,
    Button: buttonWhiteTheme,
    anyButton: {
      modalAcceptButton: buttonDefaultTheme,
      modalCancelButton: buttonWhiteTheme,
    },
    tab: {
      item: buttonTabItemTheme,
      selectedItem: buttonSelectedTabItemTheme,
    },
    actionBar: actionBarDefaultTheme,
    reportsList: reportsListDefaultTheme,
    Dropdown: dropdownDefaultTheme,
  },
  loginTheme: {
    Sidebar: sidebarLoginTheme,
    PageLayout: pageLayoutLoginTheme,
    Button: buttonDefaultTheme,
    Input: inputDefaultTheme,
    Select: selectLoginTheme,
  },
  themeKZ: {
    Table: tableDefaultThemeKZ,
    Menu: menuDefaultThemeKZ,
    Sidebar: sidebarDefaultThemeKZ,
    PageLayout: pageLayoutDefaultThemeKZ,
    Header: headerDefaultThemeKZ,
    Content: contentDefaultThemeKZ,
    TableFilter: tableFilterDefaultThemeKZ,
    Input: inputDefaultThemeKZ,
    Select: selectLoginThemeKZ,
    Button: buttonWhiteThemeKZ,
    anyButton: {
      modalAcceptButton: buttonDefaultThemeKZ,
      modalCancelButton: buttonWhiteThemeKZ,
    },
    tab: {
      item: buttonTabItemThemeKZ,
      selectedItem: buttonSelectedTabItemThemeKZ,
    },
    actionBar: actionBarDefaultThemeKZ,
    reportsList: reportsListDefaultThemeKZ,
    Dropdown: dropdownDefaultThemeKZ,
  },
  loginThemeKZ: {
    Sidebar: sidebarLoginThemeKZ,
    PageLayout: pageLayoutLoginThemeKZ,
    Button: buttonDefaultThemeKZ,
    Input: inputDefaultThemeKZ,
    Select: selectLoginThemeKZ,
  },

  themeUZ: {
    Menu: menuDefaultThemeUZ,
    Select: selectLoginThemeUZ,
    Button: buttonWhiteThemeUZ,
    anyButton: {
      modalAcceptButton: buttonDefaultThemeUZ,
      modalCancelButton: buttonWhiteThemeUZ,
    },
    tab: {
      item: buttonTabItemThemeUZ,
      selectedItem: buttonSelectedTabItemThemeUZ,
    },
    reportsList: reportsListDefaultThemeUZ,
    Dropdown: dropdownDefaultThemeUZ,
    Table: tableDefaultTheme,
    Sidebar: sidebarDefaultTheme,
    PageLayout: pageLayoutDefaultTheme,
    Header: headerDefaultTheme,
    Content: contentDefaultTheme,
    TableFilter: tableFilterDefaultTheme,
    Input: inputDefaultTheme,
    actionBar: actionBarDefaultTheme,
  },
  loginThemeUZ: {
    Select: selectLoginThemeUZ,
    Sidebar: sidebarLoginTheme,
    PageLayout: pageLayoutLoginTheme,
    Button: buttonDefaultTheme,
    Input: inputDefaultTheme,
  },

  Registration: {
    url: '/private-office-api/private/v2/participants/cert/reg',
    theme: {
      Sidebar: sidebarRegistrationTheme,
      Select: selectRegistrationTheme,
      Input: inputDefaultTheme,
      Button: buttonDefaultTheme,
    },
    themeKZ: {
      Sidebar: sidebarRegistrationThemeKZ,
      Select: selectRegistrationThemeKZ,
      Input: inputDefaultThemeKZ,
      Button: buttonDefaultThemeKZ,
    },
  },
  AltScenarios: {
    theme: {
      SinglePercentChart: singlePercentChartUsualTheme,
      MultiPercentChart: multiPercentChartUsualTheme,
      SmallLineChart: smallLineChartUsualTheme,
    },
  },
  dadataSettings: {
    apiKey: '4aa7b20b8ab4c8e133b1e5e41b782faf30e58f1e',
    apiEmailURL:
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/email',
    apiAddrURL:
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
  },
  extend: {
    routes: [
      {
        orgRoles: CAN_SEE_PRODUCTS,
        exclude: [],
        include: [
          {
            route: '/products/list',
            exact: true,
            private: true,
            inLayout: true,
            component: ProductListBound,
          },
          {
            route: '/products/list/:id',
            private: true,
            inLayout: true,
            component: ProductDetailsBound,
          },
        ],
      },
      {
        //orgRoles: CAN_SEE_REPORTS,
        exclude: [], //NOT IMPLEMENTED
        include: [
          {
            route: '/reports',
            private: true,
            exact: false,
            inLayout: true,
            component: Reports,
          },
        ],
      },
      {
        orgRoles: CAN_SEE_MOBILE,
        exclude: [],
        include: [
          {
            route: '/mobile-content/articles',
            private: true,
            exact: false,
            inLayout: true,
            component: ArticleListPage,
          },
        ],
      },
      {
        orgRoles: CAN_SEE_HOMEPAGE,
        exclude: [],
        include: [
          {
            route: '/home',
            private: true,
            exact: false,
            inLayout: true,
            component: HomePage,
          },
          // {
          //   route: '/charts',
          //   exact: true,
          //   private: true,
          //   inLayout: true,
          //   component: AltScenariosBound,
          // },
        ],
      },
      {
        orgRoles: CAN_SEE_PARTICIPANTS,
        exclude: [], //NOT IMPLEMENTED
        include: [
          {
            route: '/participants/list',
            exact: true,
            private: true,
            inLayout: true,
            component: ParticipantList,
          },
          ...(countryCodeFlags.isUZ
            ? [
                {
                  route: '/participants/evaders/list',
                  exact: true,
                  private: true,
                  inLayout: true,
                  component: UnauthorizedParticipants,
                },
              ]
            : []),
          {
            route: '/participants/list/:inn',
            private: true,
            inLayout: true,
            component: ParticipantDetailsBound,
          },
        ],
      },
      {
        orgRoles: CAN_SEE_GRAY_AREA,
        exclude: [], //NOT IMPLEMENTED
        include: [
          // {
          //   route: '/gray-area/:id',
          //   private: true,
          //   inLayout: true,
          //   component: GrayArea,
          // },
          // {
          //   route: '/gray-area',
          //   exact: true,
          //   private: true,
          //   inLayout: true,
          //   component: GrayArea,
          // },
          // {
          //   route: '/gray-area-create',
          //   exact: true,
          //   private: true,
          //   inLayout: true,
          //   component: GrayArea,
          // },
        ],
      },
      {
        orgRoles: CAN_SEE_UNLOAD_SERVICE,
        exclude: [], //NOT IMPLEMENTED
        include: [
          // {
          //   route: '/unload-service/list/:id',
          //   private: true,
          //   inLayout: true,
          //   component: UnloadServiceDetails,
          // },
          // {
          //   route: '/unload-service/list',
          //   exact: true,
          //   private: true,
          //   inLayout: true,
          //   component: UnloadServiceList,
          // },
        ],
      },
    ],
  },
};
