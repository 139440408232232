import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@crpt-ui/menu';
import { MenuItem } from './MenuItem/MenuItem';

const PageMenuView = ({ items, Header }) => {
  const currentActive = items.filter(item =>
    location.pathname.includes(item.route || item.testRoute),
  )[0];
  const activeRoute = currentActive
    ? currentActive.route || currentActive.testRoute
    : '/';
  const [currentPath, setCurrentPath] = useState(activeRoute);

  return (
    <div
      data-test="component-page-menu"
      style={{
        zIndex: '9',
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        overflow: 'auto',
      }}
    >
      <Menu expandable header={<Header />}>
        {items.map((item, index) => (
          <MenuItem
            onItemClick={setCurrentPath}
            key={index}
            item={item}
            active={
              currentPath === item.route || currentPath === item.testRoute
            }
          />
        ))}
      </Menu>
    </div>
  );
};

PageMenuView.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      titleKey: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      testRoute: PropTypes.string,
    }),
  ).isRequired,
};

export default PageMenuView;
