import 'datejs';
import 'moment/locale/ru';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Box, Grid, withTheme } from '@material-ui/core';
import { documentTypeNames } from 'src/constants/documentTypes';
import { Button, themes as BUTTON_THEMES } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import { getDocumentStatusIconByDocStatus } from '../DocumentDetails.utils';
import { documentStatusesNames } from 'src/constants/documentStatuses';
import {
  docKindsNamesUz,
  reasonCancellationNamesUz,
} from '../../../motp_components/_Requests/RequestList/NotificationOfEntry/NotificationOfEntry.constants';
import { format } from 'date-fns';

moment.locale('ru');

export const PageWrapper = styled.div`
  min-height: calc(100vh - 190px);
  padding: 34px;
`;

export const CardHeading = styled.div`
  font-size: 24px;
`;

export const ParagraphHeading = styled.div`
  font-size: 18px;
  margin-top: 18px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const BorderBottomWrapper = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid lightgray;
`;

const WithdrawalFromCirculation = props => {
  const { id, selectedDocument } = props;
  const { documentDate } = selectedDocument;
  const { t } = useTranslation();
  const docDate = moment(new Date(documentDate)).format('DD.MM.YYYY');
  const eliminationReason = _.get(selectedDocument, 'eliminationReason', null);
  const number = _.get(selectedDocument, 'sourceDocumentNumber', '-');
  const date = _.get(selectedDocument, 'sourceDocumentDate', '-');

  const formattedDate = format(new Date(date), 'dd.MM.yyyy');

  const senderInn = _.get(selectedDocument, 'senderInn', '-');
  const senderName = _.get(selectedDocument, 'senderName', '-');
  const type = _.get(selectedDocument, 'sourceDocumentType', null);
  const docType = _.get(selectedDocument, 'type', null);

  return (
    <PageWrapper>
      <Grid container justify="space-between">
        <Grid item>
          <CardHeading>
            <Box fontWeight={600}>{t(documentTypeNames[docType])}</Box>
            ID {id}
          </CardHeading>
        </Grid>
        <Grid item pr={0}>
          <Button theme={BUTTON_THEMES.whiteTheme}>
            <Icon
              type={getDocumentStatusIconByDocStatus(selectedDocument.status)}
              spacingRight={9}
            />
            {t(documentStatusesNames[selectedDocument.status])}
          </Button>
        </Grid>
      </Grid>
      <Box width="75%">
        <BorderBottomWrapper>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={4}>
              <Box fontWeight={600}>{t('Поступил в систему')}</Box>
            </Grid>
            <Grid item xs={6}>
              {documentDate ? docDate : '-'}
            </Grid>
          </Grid>
        </BorderBottomWrapper>
        <ParagraphHeading>{t('Отправитель')}</ParagraphHeading>
        <BorderBottomWrapper>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={4}>
              <Box fontWeight={600}>{t('ИНН/ПИНФЛ')}</Box>
            </Grid>
            <Grid item xs={6}>
              {senderInn}
            </Grid>
          </Grid>
        </BorderBottomWrapper>
        <BorderBottomWrapper>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={4}>
              <Box fontWeight={600}>{t('Наименование')}</Box>
            </Grid>
            <Grid item xs={6}>
              {senderName}
            </Grid>
          </Grid>
        </BorderBottomWrapper>
        <ParagraphHeading>{t('Данные-основание')}</ParagraphHeading>
        <BorderBottomWrapper>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={4}>
              <Box fontWeight={600}>{t('Вид документа')}</Box>
            </Grid>
            <Grid item xs={6}>
              {type ? t(docKindsNamesUz[type]) : '-'}
            </Grid>
          </Grid>
        </BorderBottomWrapper>
        <BorderBottomWrapper>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={4}>
              <Box fontWeight={600}>{t('Номер документа')}</Box>
            </Grid>
            <Grid item xs={6}>
              {number}
            </Grid>
          </Grid>
        </BorderBottomWrapper>
        <BorderBottomWrapper>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={4}>
              <Box fontWeight={600}>{t('Дата документа')}</Box>
            </Grid>
            <Grid item xs={6}>
              {formattedDate}
            </Grid>
          </Grid>
        </BorderBottomWrapper>
        <BorderBottomWrapper>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={4}>
              <Box fontWeight={600}>{t('Причина вывода из оборота')}</Box>
            </Grid>
            <Grid item xs={6}>
              {eliminationReason
                ? t(reasonCancellationNamesUz[eliminationReason])
                : '-'}
            </Grid>
          </Grid>
        </BorderBottomWrapper>
      </Box>
    </PageWrapper>
  );
};

WithdrawalFromCirculation.propTypes = {};

export default WithdrawalFromCirculation;
